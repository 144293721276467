<template>
    <quill-editor v-model="brief"
                  class="editer"
                  name="image"
                  :options="editorOption"
                  @change="onEditorChange"
                  ></quill-editor>

</template>
<!--@ready="onEditorReady($event)"-->
<style>
    .ql-toolbar.ql-snow {
        line-height: initial;
    }
</style>
<script type="text/javascript">
    import { quillEditor } from "vue-quill-editor"; //富文本编辑器 
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";
    import "quill/dist/quill.bubble.css";  
    const uploadConfig = {
        action: '/FileServer/Upload',// '/Common/UploadFile',  // 必填参数 图片上传地址
        methods: 'POST',  // 必填参数 图片上传方式
        token: '',  // 可选参数 如果需要token验证，假设你的token有存放在sessionStorage
        name: 'image',  // 必填参数 文件的参数名
        size: 500,  // 可选参数   图片大小，单位为Kb, 1M = 1024Kb
        ProcessorName: 'Image',
        JsonDataParams: "",
        accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'  // 可选 可上传的图片格式
    };

    // toolbar工具栏的工具选项（默认展示全部）
    // const toolOptions = [
    //     ['htmlEditButton'],
    //     ['bold', 'italic', 'underline', 'strike'],
    //     ['blockquote', 'code-block'],
    //     [{ 'header': 1 }, { 'header': 2 }],
    //     [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    //     [{ 'script': 'sub' }, { 'script': 'super' }],
    //     [{ 'indent': '-1' }, { 'indent': '+1' }],
    //     [{ 'direction': 'rtl' }],
    //     [{ 'size': ['small', false, 'large', 'huge'] }],
    //     [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    //     [{ 'color': [] }, { 'background': [] }],
    //     [{ 'font': [] }],
    //     [{ 'align': [] }],
    //     ['clean'],
    //     ['link', 'image', 'video']
    // ];


        const toolOptions = [
       
       ['bold'],
       [{ 'size': ['small', false, 'large', 'huge'] }],
       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
       [ 'image'],
       ['blockquote'],
       [{ 'color': [] }],
       [{ 'align': [] }],
        //  [{ 'header': 1 }, { 'header': 2 }],
        // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        // [{ 'script': 'sub' }, { 'script': 'super' }],
        // [{ 'indent': '-1' }, { 'indent': '+1' }],
        // [{ 'direction': 'rtl' }],
        // [{ 'font': [] }],
        // ['clean'], [ 'image', 'video']
         ['video'],
          ['htmlEditButton'],
    ];
    const handlers = {
        image: function () {
            var self = this;
            var fileInput = this.container.querySelector('input.ql-image[type=file]');
            if (fileInput === null) {
                fileInput = document.createElement('input');
                fileInput.setAttribute('type', 'file');
                // 设置图片参数名
                if (uploadConfig.name) {
                    fileInput.setAttribute('name', uploadConfig.name);
                    // fileInput.setAttribute('ProcessorName', uploadConfig.name);
                }
                // 可设置上传图片的格式
                fileInput.setAttribute('accept', uploadConfig.accept);
                fileInput.classList.add('ql-image');
                // 监听选择文件
                fileInput.addEventListener('change', function () {
                    // 创建formData
                    var formData = new FormData();
                    formData.append(uploadConfig.name, fileInput.files[0]);
                    formData.append('object', 'product');
                    formData.append('ProcessorName', uploadConfig.name);
                    // 如果需要token且存在token
                    if (uploadConfig.token) {
                        formData.append('token', uploadConfig.token)
                    }
                    // 图片上传
                    var xhr = new XMLHttpRequest();
                    xhr.open(uploadConfig.methods, uploadConfig.action, true);
                    // 上传数据成功，会触发
                    xhr.onload = function () {
                        if (xhr.status === 200) {
                            var res = JSON.parse(xhr.responseText);
                            let length = self.quill.getSelection(true).index;
                            //这里很重要，你图片上传成功后，img的src需要在这里添加，res.path就是你服务器返回的图片链接。
                            // var img = "<img src='"+res+"'>"
                            self.quill.insertEmbed(length, 'image', res);
                            self.quill.setSelection(length + 1)
                        }
                        fileInput.value = ''
                    };
                    // 开始上传数据
                    xhr.upload.onloadstart = function () {
                        fileInput.value = ''
                    };
                    // 当发生网络异常的时候会触发，如果上传数据的过程还未结束
                    xhr.upload.onerror = function () {
                        console.log("error");
                    };
                    // 上传数据完成（成功或者失败）时会触发
                    xhr.upload.onloadend = function () {
                        console.log('上传结束')
                    };
                    xhr.send(formData)
                });
                this.container.appendChild(fileInput);
            }
            fileInput.click();
        },
        htmlEditButton: function () {
            const reg = /<br>/g,
                container = this.container,
                firstChild = container.nextElementSibling.firstChild;
            if (!this.shadeBox) {
                let shadeBox = this.shadeBox = document.createElement('div');
                shadeBox.style.cssText = 'position:absolute; top:0; left:0; width:100%; height:100%; background:rgba(0,0,0,0.5); cursor:pointer';
                container.style.position = 'relative';
                shadeBox.addEventListener('click', function () {
                    this.style.display = 'none';
                    firstChild.innerHTML = firstChild.innerText.trim();
                }, false);
                container.appendChild(shadeBox);
                let innerHTML = firstChild.innerHTML;
                innerHTML = innerHTML.replace(reg, '');
                firstChild.innerText = innerHTML;
            } else {
                let innerHTML = firstChild.innerHTML;
                innerHTML = innerHTML.replace(reg, '');
                firstChild.innerText = innerHTML;
                this.shadeBox.style.display = 'block';
            } 
        }
    };
    export default {
        components: {
            quillEditor//,quillRedefine
        },
        props: {
            value: {
                type: String,
                default: ""
            }
        },
        data() {
            return { 
                editorOption: {
                    theme: "snow",
                    boundary: document.body,
                    modules: {
                        toolbar: {
                            container: toolOptions,  // 工具栏选项
                            handlers: handlers  // 事件重写
                        } 
                    },
                    placeholder: '请输入...'
                },
                brief: this.value
            };
        },
        watch: {
            value: function (val) {
                this.brief = val;
            }
        },
        methods: {
            onEditorChange(val) {
                this.$emit("update:value", val.html);
            },
            initButton: function () {      //在使用的页面中初始化按钮样式
                const sourceEditorButton = document.querySelector('.ql-htmlEditButton');
                sourceEditorButton.style.cssText = "width:40px;";
                sourceEditorButton.innerText = "源码";
            } 
        },
        mounted: function () {//生命周期，组装完成
            this.initButton();
        }
    };
</script>    
<style>
    .ql-editor{
        overflow:scroll;
    }
    .editor {
      line-height: normal !important;
      height: 800px;
    }
    .ql-snow .ql-tooltip[data-mode=link]::before {
      content: "请输入链接地址:";
    }
    .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
        border-right: 0px;
        content: '保存';
        padding-right: 0px;
    }

    .ql-snow .ql-tooltip[data-mode=video]::before {
        content: "请输入视频地址:";
    }
.ql-toolbar.ql-snow + .ql-container.ql-snow{
    height: 300px;
}
    .ql-snow .ql-picker.ql-size .ql-picker-label::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item::before {
      content: '14px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
      content: '10px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
      content: '18px';
    }
    .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
    .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
      content: '32px';
    }

    .ql-snow .ql-picker.ql-header .ql-picker-label::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item::before {
      content: '文本';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
      content: '标题1';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
      content: '标题2';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
      content: '标题3';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
      content: '标题4';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
      content: '标题5';
    }
    .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
    .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
      content: '标题6';
    }

    .ql-snow .ql-picker.ql-font .ql-picker-label::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item::before {
      content: '标准字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
      content: '衬线字体';
    }
    .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
      content: '等宽字体';
    }
    .ql-align-center{
      text-align: center;
    }
    .ql-align-right{
      text-align: right;
    }
    .ql-align-left{
      text-align: left;
    }
    .ql-video{width:100%;height: 100vh;}
    .ql-tooltip[data-mode='video']{left:50% !important;width:50%;transform: translateX(-50%);}
</style>
